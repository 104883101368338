import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import PageLoader from './PageLoader';

const WrapperComponent = ({
  Component,
  authenticationRequired,
  path,
  ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth();

  // useEffect(() => {
  //   if (authenticationRequired && !loading && !isAuthenticated) {
  //     loginWithRedirect({
  //       appState: {
  //         target: path,
  //       },
  //     });
  //   }
  // }, [authenticationRequired, loading, isAuthenticated]);

  if (authenticationRequired && !loading && !isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (loading || (authenticationRequired && !isAuthenticated)) {
    return <PageLoader />;
  }

  return <Component {...rest} />;
};

export default ({ component, path, authenticationRequired, ...rest }) => {
  const render = props => (
    <WrapperComponent
      path={path}
      Component={component}
      authenticationRequired={authenticationRequired}
      {...props}
    />
  );
  return <Route path={path} render={render} {...rest} />;
};

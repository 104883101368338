import ApolloClient from 'apollo-boost';
import config from '../config';
import { getTokenSilently } from './auth';

export const apolloClient = new ApolloClient({
  uri: config.endpoint,
  fetchOptions: {
    credentials: 'include',
  },
  request: async operation => {
    let token;
    try {
      token = await getTokenSilently();
    } catch (e) {
      // fall-through if not logged in
    }

    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }
  },
});

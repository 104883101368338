import React from 'react';
import Div100vh from 'react-div-100vh';
import loader from '../images/loader.svg';

export default function PageLoader() {
  return (
    <Div100vh
      style={{ minHeight: '100rvh' }}
      className="flex items-center justify-center text-center"
    >
      <img src={loader} />
    </Div100vh>
  );
}

import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import config from './config';
import { Auth0Provider } from './services/auth';
import { apolloClient } from './services/apollo';

import CustomRoute from './components/CustomRoute';
import PageLoader from './components/PageLoader';

/* eslint-disable import/first */
const Activate = lazy(() => import('./pages/Activate'));
const Home = lazy(() => import('./pages/Home'));
const ClaimWorkflow = lazy(() =>
  import(/* webpackPrefetch: true */ './pages/ClaimWorkflow'),
);

import './App.css';
import 'react-select/dist/react-select.css';

const App = () => (
  <div className="App">
    <Auth0Provider
      domain={config.authDomain}
      client_id={config.authClientId}
      audience={config.authAudience}
      redirect_uri={config.authRedirectUri}
      logout_redirect_uri={config.authRedirectUri}
    >
      <ApolloProvider client={apolloClient}>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <CustomRoute exact path="/activate" component={Activate} />
            <CustomRoute exact path="/" component={Home} />
            <CustomRoute
              path="/workflows/:workflowId"
              component={ClaimWorkflow}
              authenticationRequired
            />
          </Switch>
        </Suspense>
      </ApolloProvider>
    </Auth0Provider>
  </div>
);

export default App;

import * as Sentry from '@sentry/browser';
import config from './config';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const canHover = !matchMedia('(hover: none)').matches;
if (canHover) {
  document.body.classList.add('can-hover');
}

Sentry.init({
  dsn: config.sentryDsn,
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

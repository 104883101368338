import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let _magicModeToken = null;

let _auth0Client;
let pendingRequests = [];
export const getTokenSilently = () => {
  if (_magicModeToken) {
    return `magic_${_magicModeToken}`;
  }
  return null;
  // if (_auth0Client) {
  //   return _auth0Client.getTokenSilently().then(t => `user_${t}`);
  // } else {
  //   return new Promise((resolve, reject) => {
  //     pendingRequests.push({ resolve, reject });
  //   });
  // }
};

const MAGIC_TOKEN_STORAGE_KEY = 'assured$$magicToken';
const getMagicToken = () => {
  const params = new URLSearchParams(window.location.search);
  let magic = params.get('magic');
  if (magic) {
    return magic;
  }
  if (window.sessionStorage && window.sessionStorage[MAGIC_TOKEN_STORAGE_KEY]) {
    return window.sessionStorage[MAGIC_TOKEN_STORAGE_KEY];
  }
  return null;
};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  logout_redirect_uri,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const initMagicMode = async token => {
    _magicModeToken = token;
    setIsAuthenticated(true);
    setUser({});
    const client = { getTokenSilently };
    setAuth0(client);
    try {
      window.sessionStorage[MAGIC_TOKEN_STORAGE_KEY] = token;
    } catch (e) {
      console.log('Failed to save session token', e);
    }
    onRedirectCallback({});
    setLoading(false);
  };

  const postHook = async () => {
    // Respond to token requests
    pendingRequests.forEach(({ resolve, reject }) =>
      isAuthenticated
        ? getTokenSilently().then(resolve).catch(reject)
        : resolve(),
    );
    pendingRequests = [];
  };

  useEffect(() => {
    const magicToken = getMagicToken();
    if (magicToken) {
      initMagicMode(magicToken).then(postHook);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const loginWithMagicToken = async token => {
    return initMagicMode(token).then(postHook);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        loginWithMagicToken,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) =>
          auth0Client.logout({ returnTo: logout_redirect_uri, ...p }),
        logoutRaw: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
